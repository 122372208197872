import { Component } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-help-toast',
  templateUrl: './help-toast.component.html',
  styleUrls: ['./help-toast.component.scss']
})
export class HelpToastComponent {

  constructor(public languageService:LanguageService) { }

}
