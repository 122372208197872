import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DataType, TableColumn } from '@etech-flex-sre/ls-sortable-table';
import { OrgGroup } from 'src/app/interfaces/org-group';
import { OrgUnit } from 'src/app/interfaces/org-unit';
import { Permissions } from 'src/app/interfaces/permissions';
import { ModalService } from 'src/app/services/modal.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions-modal.component.html',
  styleUrls: ['./permissions-modal.component.scss']
})
export class PermissionsModalComponent implements OnInit, AfterViewInit, OnDestroy {
	public columns:Array<TableColumn<Permissions>> = [
		{
			ColumnName: 'Org Name',
			ColumnKey: 'OrgUnitName',
			AutoSort: true,
			SortDirection: true,
			Sortable: true,
		},
		{
			ColumnName: 'Org Code',
			ColumnKey: 'OrgUnitCode',
		},
		{
			ColumnName: 'Can View',
			ColumnKey: 'CanView',
			DataType: DataType.Boolean,
		},
		{
			ColumnName: 'Can Schedule',
			ColumnKey: 'CanSchedule',
			DataType: DataType.Boolean,
		},
		{
			ColumnName: 'Can Manage',
			ColumnKey: 'CanManage',
			DataType: DataType.Boolean,
		},
		{
			ColumnName: 'Can Submit On Behalf',
			ColumnKey: 'CanOnBehalf',
			DataType: DataType.Boolean,
		},
		{
			ColumnName: 'Can Build Profiles',
			ColumnKey: 'CanBuild',
			DataType: DataType.Boolean,
		},
		{
			ColumnName: 'Is Admin',
			ColumnKey: 'IsAdmin',
			DataType: DataType.Boolean,
		},
	];
	public shownPermissions:Array<Permissions> = [];
	public userOrgGroups = this.permissionsService.userOrgGroups;
	private userOrgUnits = this.permissionsService.userOrgUnits;
	public filteredOrgUnits:Array<OrgUnit> = [];
	public selectedOrgUnits:Array<OrgUnit> = [];
	private userPermissions:Array<Permissions> = [];
	public filterOrgGroup:OrgGroup | null = null;

  constructor(private modalService:ModalService,
		public permissionsService:PermissionsService) {

		this.permissionsService.permissionsChanged
			.subscribe((permissions) => {
				this.userPermissions = [...permissions];
				this.shownPermissions = [];
				this.userOrgGroups = this.permissionsService.userOrgGroups;
				this.userOrgUnits = this.permissionsService.userOrgUnits;
			});

	}

	ngOnInit(): void {
	}

  ngAfterViewInit(): void {
		this.modalService.initModal('permissions-modal');
		this.modalService.addEventListener(
			'permissions-modal',
			'hidden.bs.modal',
			() => this.reset()
		);
	}

	ngOnDestroy(): void {
		this.modalService.disposeModal('permissions-modal');
	}

	updateFilterOrgGroup(orgGroup:OrgGroup | null) {
		console.log('selected org group', orgGroup);
		this.selectedOrgUnits = [];
		this.shownPermissions = [];

		if(!orgGroup) {
			this.filteredOrgUnits = [];
			return;
		}

		this.filteredOrgUnits = this.userOrgUnits.filter(ou => ou.OrgGroupID === orgGroup.OrgGroupID);
		this.selectedOrgUnits = this.filteredOrgUnits;
		this.updateFilterOrgUnits(this.selectedOrgUnits);
	}

	updateFilterOrgUnits(filter:Array<OrgUnit>) {
		const orgUnitIds = filter.map(f => f.OrgUnitID);
		this.shownPermissions = this.userPermissions.filter(up => orgUnitIds.includes(up.OrgUnitID!));
	}

	reset() {
		this.shownPermissions = [];
		this.selectedOrgUnits = [];
		this.filteredOrgUnits = [];
		this.filterOrgGroup = null;
	}

}
