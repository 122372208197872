import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService) { }

  intercept(req:HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
    const t = this.authService.authUser ? this.authService.authUser.token : null;

    const h = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: t ? `Bearer ${t}` : '',
    };

    return next.handle(req.clone({
      setHeaders: h,
    }));
  }

}
