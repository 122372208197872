import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import '@wdpr/button';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionTimeoutWarningModalService } from 'src/app/services/session-timeout-warning-modal.service';

@Component({
  selector: 'app-session-timeout-warning-modal',
  templateUrl: './session-timeout-warning-modal.component.html',
  styleUrls: ['./session-timeout-warning-modal.component.scss'],
})
export class SessionTimeoutWarningModalComponent implements AfterViewInit, OnDestroy {

  constructor(public authService:AuthService,
		public languageService:LanguageService,
		private sessionTimeoutWarningModal:SessionTimeoutWarningModalService,
		private modalService:ModalService) { }

  ngAfterViewInit(): void {
    this.modalService.initModal('session-timeout-warning-modal');
		this.modalService.addEventListener(
			'session-timeout-warning-modal',
			'hidden.bs.modal',
			() => this.sessionTimeoutWarningModal.setIsOpen(false)
		);
  }

	ngOnDestroy(): void {
		this.modalService.disposeModal('session-timeout-warning-modal');
	}
}
