<nav class="navbar navbar-expand-lg">
	<div class="container-fluid">
		<a class="navbar-brand" routerLink="/">{{ environment.production ? '' : 'DEV ' }}Cast Scheduling Profile</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
			<i class="bi bi-list text-white"></i>
		</button>
		<div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ng-container *ngIf="authService.authUser">
				<ul class="navbar-nav me-auto">
					<li class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/profile">
							{{ languageService.getTranslation('NAVBAR.YOUR_PROFILE') | async }}
						</a>
					</li>
					<li *ngIf="permissionsService.canSchedule || permissionsService.canView" class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/scheduler">Scheduler</a>
					</li>
					<li *ngIf="permissionsService.canBuild || permissionsService.canManage" class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/profile-templates">Profile Builder</a>
					</li>
					<li *ngIf="permissionsService.canView" class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/insights">Insights</a>
					</li>
					<li *ngIf="permissionsService.canManage || permissionsService.isAdmin || permissionsService.isSuperAdmin" class="nav-item">
						<a class="nav-link" aria-current="page" routerLink="/admin">Admin</a>
					</li>
				</ul>
				<div class="d-flex flex-wrap">
					<div>
						<select class="form-select form-select-sm" [(ngModel)]="languageService.activeLanguage" (ngModelChange)="updateActiveLanguage($event)">
							<option value="en">English</option>
							<option value="es">Español</option>
						</select>
					</div>
					<button type="button" class="btn ms-3 mb-2 mb-lg-0" routerLink="/logout">
						{{ languageService.getTranslation('NAVBAR.SIGN_OUT') | async }}
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</nav>
<div class="user-img-section">
	<div class="session-timer">
	<div class="circle" #circle></div>
	<div class="circle-timer">
		<div class="circle-image">
			<ng-container *ngIf="authService.authUser">
				<img id="user-img" class="rounded-circle" [src]="authService.authUser.original_photo_url">
			</ng-container>
		</div>
		<div class="countdown w-100">
			<p class="caption">Time Remaining:<br/><span *ngIf="authService.minutesUntilTimeout > 0" class="timer">{{ authService.minutesUntilTimeout | number:'1.0-0' }}</span><span *ngIf="authService.minutesUntilTimeout > 1"> Minutes</span><span *ngIf="authService.minutesUntilTimeout == 1"> Minute</span><span *ngIf="authService.minutesUntilTimeout < 1">{{ authService.secondsUntilTimeout  | number:'2.0-0'  }} Seconds</span></p>
		</div>
		<div class="info-box">
			<h4 class="info-header">Session Countdown</h4>
			<p class="info-text">This sessoin will expire in <b>{{ authService.minutesUntilTimeout | number:'1.0-0'  }}:{{ authService.secondsUntilTimeout | number:'2.0-0'  }}</b>. Please save your work before that time.</p>
		</div>
	</div>
	</div>
</div>

