export enum ErrorCode {
	'no-prior-submissions' = 'NO_PRIOR_SUBMISSION',
	'ETIMEOUT' = 'SERVER_TIMEOUT',
	'EREQUEST' = 'REQUEST_ERROR',
	'TokenExpiredError' = 'TOKEN_EXPIRED',
	'JsonWebTokenError' = 'TOKEN_ERROR',
	'server' = 'SERVER_ERROR',
	'missing-required-parameters' = 'MISSING_REQUIRED_PARAMS',
	'missing-required-body' = 'MISSING_REQUIRED_BODY',
	'invalid-data' = 'INVALID_DATA',
	'insufficient-permissions' = 'INSUFF_PERMISSIONS',
	'form-not-found' = 'FORM_NOT_FOUND',
	'user-not-found' = 'USER_NOT_FOUND',
	'profile-not-found' = 'PROFILE_NOT_FOUND',
	'bad-request' = 'BAD_REQUEST',
};
