import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Notification } from 'src/app/interfaces/notification';

@Component({
  selector: 'app-notification-carousel',
  templateUrl: './notification-carousel.component.html',
  styleUrls: ['./notification-carousel.component.scss'],
})
export class NotificationCarouselComponent implements OnChanges {
	@Output() notificationSeen = new EventEmitter<Notification>();
	@Input() notifications:Array<Notification> = [];
	public idx = 0;
	public activeNotification:Notification | null = null;

  constructor() { }

	ngOnChanges(changes: SimpleChanges): void {
		// eslint-disable-next-line dot-notation
		if(changes && changes['notifications']) {
			// eslint-disable-next-line dot-notation
			if(changes['notifications'].currentValue.length > 0) {
				// eslint-disable-next-line dot-notation
				[this.activeNotification] = changes['notifications'].currentValue;
				this.notificationSeen.emit(this.activeNotification!);
			}
		}
	}

	next() {
		this.idx += 1;
		this.activeNotification = this.notifications[this.idx];
		this.notificationSeen.emit(this.activeNotification);
	}

	previous() {
		this.idx -= 1;
		this.activeNotification = this.notifications[this.idx];
		this.notificationSeen.emit(this.activeNotification);
	}

}
