import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-profile-builder-management-help',
  templateUrl: './profile-builder-management-help.component.html',
  styleUrls: ['./profile-builder-management-help.component.scss']
})
export class ProfileBuilderManagementHelpComponent implements OnInit {

  constructor(public languageService:LanguageService) { }

  ngOnInit(): void {
  }

}
