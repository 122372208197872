<div *ngIf="error" class="p-3 rounded bg-danger text-white">
	<div class="d-flex justify-content-between" *ngIf="error.errorType">
		<div class="hyperion-t6">
			{{ languageService.getTranslation(getTranslationKey(error.errorType)) | async }}
		</div>
		<button
			class="btn btn-danger"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#error-collapse-more-info"
			aria-expanded="false"
			aria-controls="error-collapse-more-info"
			(click)="collapsed = !collapsed"
		>
			<ng-container *ngIf="collapsed; else expandedText">
				Show
			</ng-container>
			<ng-template #expandedText>
				Hide
			</ng-template>
			 More Information
			<ng-container *ngIf="collapsed; else expandedIcon">
				<i class="bi bi-caret-up-fill"></i>
			</ng-container>
			<ng-template #expandedIcon>
				<i class="bi bi-caret-down-fill"></i>
			</ng-template>
		</button>
	</div>
	<div class="collapse" id="error-collapse-more-info">
		<div class="hyperion-t6" *ngIf="error.errorType">Error: {{ error.errorType }}</div>
		<div class="hyperion-t6" *ngIf="error.additionalInfo">Additional info: {{ error.additionalInfo }}</div>
	</div>
</div>
