import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit {

  constructor(public authService:AuthService,
							public languageService:LanguageService,
							public permissionsService:PermissionsService) { }

	updateActiveLanguage(value:'en' | 'es') {
		this.languageService.setActiveLanguage(value);
	}

	get environment() {
		return environment;
	}

	@ViewChild('circle') circle: ElementRef | undefined;

	clipPath: string =  "";

	ngAfterViewInit(): void {
		const countdown = this.circle?.nativeElement;
		this.animateClipPath(countdown);
	}

	get minutes(): number {
		return this.authService.minutesUntilTimeout;
	}

	get seconds(): number {
		return this.authService.secondsUntilTimeout;
	}

	animateClipPath(element: HTMLElement) {
	
		const top = 60 * 60;
		const cutPoint50 = 50 * 60;
		const cutPoint40 = 40 * 60;
		const cutPoint30 = 30 * 60;
		const cutPoint20 = 20 * 60;
		const cutPoint10 = 10 * 60;

		const animate = () => {
		
			const timer = this.minutes * 60 + this.seconds;
			
			// Original clip: polygon(0% 50%, 0% 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0%, 50% 0%, 50% 50%)
			if(timer >= cutPoint50 ) {
				this.clipPath = `polygon(0% ${50 + this.percent(timer,top,cutPoint50)}%, 0% 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0%, 50% 0%, 50% 50%)`;      
			} else if (timer >= cutPoint40) {
				this.clipPath = `polygon(${0 + this.percent(timer,cutPoint50,cutPoint40)}% 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0%, 50% 0%, 50% 50%)`;        
			} else if (timer >= cutPoint30) {
				this.clipPath = `polygon(${50 + this.percent(timer,cutPoint40,cutPoint30)}% 100%, 100% 100%, 100% 50%, 100% 0%, 50% 0%, 50% 50%)`;
			} else if (timer >= cutPoint20) {
				this.clipPath = `polygon(100% ${100 - this.percent(timer,cutPoint30,cutPoint20)}%, 100% 50%, 100% 0%, 50% 0%, 50% 50%)`;
			} else if (timer >= cutPoint10) {
				this.clipPath = `polygon(100% ${50 - this.percent(timer,cutPoint20,cutPoint10)}%, 100% 0%, 50% 0%, 50% 50%)`;
			} else {
				this.clipPath = `polygon(${100 - this.percent(timer,cutPoint10,0)}% 0%, 50% 0%, 50.0000000001% 50.0000000001%)`;
			}
		
			element.style.clipPath = this.clipPath;
		
			if (timer < top) {
				requestAnimationFrame(animate);
			}
		};
	
		animate();
	}
	
	percent(timer:number, topNum:number, bottomNum:number) {
		return (50*(1-((timer-bottomNum)/(topNum-bottomNum))));
	}

}
