import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notification } from '../interfaces/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private http:HttpClient) { }

	public createNotification(notification:Notification) {
		return this.http.post<{ notificationId:number }>(`${environment.baseURL}/notification`, notification);
	}

	public createOrgNotification(orgId:number, notificationId:number) {
		return this.http.post<{ OrgNotificationsID:number }>(
			`${environment.baseURL}/org-unit/${orgId}/notifications`,
			{ NotificationID: notificationId }
		);
	}

	public updateNotification(notification:Notification) {
		return this.http.put<{ id:number }>(
			`${environment.baseURL}/notification/${notification.NotificationID}`,
			notification
		);
	}

	public getNotificationsForUser(perner:string) {
		return this.http.get<Array<Notification>>(`${environment.baseURL}/cast-member/${perner}/notifications`);
	}

	public ackNotification(notification:Notification, perner:string) {
		return this.http.post<{ id:number }>(
			`${environment.baseURL}/cast-member/${perner}/notification/${notification.NotificationID}`,
			{}
		);
	}

	public unackNotification(notification:Notification) {
		return this.http.put<{ id:number }>(`${environment.baseURL}/notification/${notification.NotificationID}/unack`, {});
	}

	public deleteNotification(notificationId:number) {
		return this.http.delete<{ id:number }>(`${environment.baseURL}/notification/${notificationId}`);
	}

	public getNotificationsByOrgGroupId(orgGroupId:number) {
		return this.http.get<Array<Notification>>(`${environment.baseURL}/org-group/${orgGroupId}/notifications`);
	}

	public getNotificationsByOrgUnitId(orgUnitId:number) {
		return this.http.get<Array<Notification>>(`${environment.baseURL}/org-unit/${orgUnitId}/notifications`);
	}
}
