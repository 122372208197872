import { CommonModule } from '@angular/common';
import { AccordionBodyComponent } from './accordion-body/accordion-body.component';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';
import { AccordionWrapperComponent } from './accordion-wrapper/accordion-wrapper.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LsSortableTableModule } from '@etech-flex-sre/ls-sortable-table';
import { ErrorMessageComponent } from './error-message/error-message.component';



@NgModule({
  declarations: [
    AccordionBodyComponent,
    AccordionItemComponent,
    AccordionWrapperComponent,
    ErrorMessageComponent,
  ],
  imports: [
    CommonModule,
    LsSortableTableModule,
  ],
  exports:[
    AccordionWrapperComponent,
    AccordionItemComponent,
    AccordionBodyComponent,
		ErrorMessageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
