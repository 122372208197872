import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, forkJoin, of, Subject, take, takeUntil } from 'rxjs';
import { AuthUser } from 'src/app/interfaces/auth-user';
import { Notification } from 'src/app/interfaces/notification';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationModalService } from 'src/app/services/notification-modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionTimeoutModalService } from 'src/app/services/session-timeout-modal.service';
import { SessionTimeoutWarningModalService } from 'src/app/services/session-timeout-warning-modal.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements AfterViewInit, OnInit, OnDestroy {
	private readonly $destroy = new Subject<void>();
	private canOpen = true;
	public loading = false;
	public confirming = false;
	public hasSeenAll = false;
	public errorText = '';
	public notifications:Array<Notification> = [];
	public seen:Array<Notification> = [];

  constructor(private modalService:ModalService,
							private authService:AuthService,
							private notificationService:NotificationService,
							private sessionTimeoutWarningModal:SessionTimeoutWarningModalService,
							private sessionTimeoutModal:SessionTimeoutModalService,
							private notificationModal:NotificationModalService) {
		this.authService.authUserChange
			.pipe(take(1))
			.subscribe(value => this.getNotificationsForUser(value));

		this.sessionTimeoutWarningModal.sessionTimeoutWarningModalOpenChanged
			.pipe(takeUntil(this.$destroy))
			.subscribe((isOpen) => {
				this.canOpen = !isOpen && !this.sessionTimeoutModal.isOpen;
				if(this.canOpen) this.checkShouldOpen(this.notifications);
			});

		this.sessionTimeoutModal.sessionTimeoutModalOpenChanged
			.pipe(takeUntil(this.$destroy))
			.subscribe((isOpen) => {
				this.canOpen = !isOpen && !this.sessionTimeoutWarningModal.isOpen;
				if(this.canOpen) this.checkShouldOpen(this.notifications);
			});
	}

	ngOnInit(): void {
		this.getNotificationsForUser(this.authService.authUser);
	}

  ngAfterViewInit(): void {
		this.modalService.initModal('notification-modal');
		this.modalService.addEventListener(
			'notification-modal',
			'hidden.bs.modal',
			() => {
				this.notificationModal.setIsOpen(false);
				this.reset();
			}
		);
	}

	ngOnDestroy(): void {
		this.modalService.disposeModal('notification-modal');
		this.$destroy.next();
		this.$destroy.complete();
	}

	reset() {
		this.loading = false;
		this.errorText = '';
		this.seen = [];
		this.hasSeenAll = false;
	}

	updateSeen(notification:Notification) {
		this.seen.push(notification);
		this.hasSeenAll = this.seen.length === this.notifications.length;
	}

	getNotificationsForUser(user:AuthUser | null) {
		if(user === null) return;

		this.loading = true;
		this.errorText = '';

		this.notificationService.getNotificationsForUser(user.Perner)
			.pipe(
				take(1),
				catchError((error) => {
					console.error(error);
					const { message } = error.error;
          this.errorText = `${message}`;
					return of([]);
				})
			)
			.subscribe((notifications) => {
				this.notifications = notifications;
				this.checkShouldOpen(notifications);
				this.loading = false;
			});
	}

	acknowledgeAll() {
		this.confirming = true;

		forkJoin(
			this.notifications.map(
				n => this.notificationService.ackNotification(n, this.authService.authUser!.Perner)
					.pipe(
						take(1),
						catchError((error) => {
							console.error(error);
							const { message } = error.error;
							this.errorText += `${message}\n`;
							return of(null);
						})
					)
			)
		)
			.subscribe((value) => {
				if(!this.errorText) this.notificationModal.closeModal();
				this.confirming = false;
			});
	}

	checkShouldOpen(notifications:Array<Notification>) {
		if(notifications.length === 0 || !this.canOpen) return;
		this.notificationModal.showModal();
	}

}
