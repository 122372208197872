<div class="modal fade" [id]="helpModalId">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title hyperion-t4">
					{{ languageService.getTranslation('MODAL.HELP.TITLE') | async }}
				</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="hyperion-t6 mb-3" [innerHtml]="sanitizer.bypassSecurityTrustHtml(languageService.getTranslation('MODAL.HELP.TEXT') | async)"></div>
				<ng-container [ngSwitch]="activeRoute">
					<ng-container *ngSwitchCase="'profile'">
						<ng-container [ngSwitch]="subRoute">
							<app-profile-help *ngSwitchCase="'view'"></app-profile-help>
							<app-manage-profiles-help *ngSwitchDefault></app-manage-profiles-help>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="'profile-templates'">
						<ng-container [ngSwitch]="subRoute">
							<app-profile-builder-help *ngSwitchCase="'build'"></app-profile-builder-help>
							<app-profile-builder-management-help *ngSwitchDefault></app-profile-builder-management-help>
						</ng-container>
					</ng-container>
					<app-scheduler-help *ngSwitchCase="'scheduler'"></app-scheduler-help>
					<div *ngSwitchDefault class="hyperion-t6">
						There are no tutorials written for this page yet.
					</div>
				</ng-container>
    	</div>
			<div class="modal-footer">
				<wdpr-button data-bs-dismiss="modal">
					{{ languageService.getTranslation('BUTTON.CLOSE') | async }}
				</wdpr-button>
      </div>
		</div>
	</div>
</div>
