<div class="modal fade" id="notification-modal">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header hyperion-t4">
				Cast Profile Notification
			</div>
			<div class="modal-body">
				<p class="text-danger" *ngIf="errorText">{{ errorText }}</p>
				<ls-loading-spinner [centered]="true" *ngIf="loading; else carousel"></ls-loading-spinner>
				<ng-template #carousel>
					<app-notification-carousel [notifications]="notifications" (notificationSeen)="updateSeen($event)"></app-notification-carousel>
				</ng-template>
    	</div>
			<div class="modal-footer">
				<wdpr-button (click)="acknowledgeAll()" [disabled]="loading || !hasSeenAll">
					<ls-loading-spinner *ngIf="loading || confirming; else ackText"></ls-loading-spinner>
					<ng-template #ackText>
						Acknowledge {{ notifications.length > 1 ? 'all' : '' }}
					</ng-template>
				</wdpr-button>
      </div>
		</div>
	</div>
</div>
