<div class="accordion-item">
	<h2 class="accordion-header" [id]="'heading-'+id">
		<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'+id" aria-expanded="false" [attr.aria-controls]="'collapse-'+id">
			{{ title }}
		</button>
	</h2>
	<ng-content></ng-content>
</div>


