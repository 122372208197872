import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of, Subject } from 'rxjs';
import { FormAnswerCollection } from '../interfaces/form-answer-collection';
import { FormComponent } from '../interfaces/form-component';
import { FormComponentAnswerCollection } from '../interfaces/form-component-answer-collection';
import { FormComponentOption } from '../interfaces/form-component-option';
import { FormTab } from '../interfaces/form-tab';
import { OptionRule } from '../interfaces/option-rule';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
	private readonly sessionStorageKey = 'active-lang';
	public readonly days_EN:Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	public readonly days_ES:Array<string> = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
	public activeLanguage:'en' | 'es' = 'en';

	public onLangChange = new Subject<{ lang:string, translations:any }>();

  constructor(private translate:TranslateService) {
		this.translate.onLangChange
			.subscribe((event) => this.onLangChange.next(event));

		const sessionLang = sessionStorage.getItem(this.sessionStorageKey);
		if(sessionLang) this.activeLanguage = (sessionLang as 'es' | 'en');

		this.translate.setDefaultLang(this.activeLanguage);
		this.setActiveLanguage(this.activeLanguage);
	}

	public setActiveLanguage(lang:'en' | 'es') {
		this.translate.use(lang);
		sessionStorage.setItem(this.sessionStorageKey, lang);
	}

	public getTranslation(key:string, params:any = {}) {
		return this.translate.get(key.toUpperCase(), params);
	}

	public getActiveLanguageDays() {
		return forkJoin([
			this.getTranslation('SUNDAY'),
			this.getTranslation('MONDAY'),
			this.getTranslation('TUESDAY'),
			this.getTranslation('WEDNESDAY'),
			this.getTranslation('THURSDAY'),
			this.getTranslation('FRIDAY'),
			this.getTranslation('SATURDAY'),
		]);
	}

	public getActiveLanguageComponentText(component:FormComponent | FormComponentAnswerCollection, isRequired:boolean = false) {
		let questionText;
		if(this.activeLanguage === 'en') {
			questionText = component.ComponentText_EN;
		} else {
			if(component.ComponentText_ES) {
				questionText = component.ComponentText_ES;
			} else {
				questionText = component.ComponentText_EN;
			}
		}

		return isRequired ? this.getTranslation('REQUIRED', { text: questionText }) : of(questionText);
	}

	public getActiveLanguageOptionText(option:FormComponentOption, isRequired:boolean = false) {
		let optionText;
		if(this.activeLanguage === 'en') {
			optionText = option.OptionText_EN;
		} else {
			if(option.OptionText_ES) {
				optionText = option.OptionText_ES;
			} else {
				optionText = option.OptionText_EN;
			}
		}

		return isRequired ? this.getTranslation('REQUIRED', { text: optionText }) : of(optionText);
	}

	public getActiveLanguageTabText(tab:FormTab | FormAnswerCollection) {
		if(this.activeLanguage === 'en') return tab.TabText_EN;
		if(tab.TabText_ES) return tab.TabText_ES;
		return tab.TabText_EN;
	}

	public getActiveLanguageWarningText(rule:OptionRule) {
		if(this.activeLanguage === 'en') return rule.WarningText_EN;
		if(rule.WarningText_ES) return rule.WarningText_ES;
		return rule.WarningText_EN;
	}

}
