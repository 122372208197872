import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class RouteActivator  {
	private debug = !environment.production;

  constructor(private authService:AuthService,
							private permissionsService:PermissionsService,
              private router:Router) { }

  async canActivate() {
		if(this.debug) console.log('route activating');

    if(!this.authService.authUser) {
      const sessionJSON = localStorage.getItem(environment.tokenStorageName);

      if(!sessionJSON) {
				if(this.debug) console.log('not logged in');
        this.router.navigate(['/login']);
        return false;
      }

      this.authService.setAuthUser(JSON.parse(sessionJSON));
    }

		await this.permissionsService.loadUserPermissions(this.authService.authUser!.Perner);

		if(this.debug) console.log('authorized');
    return true;
  }
}
