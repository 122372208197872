<div class="modal fade" id="session-timeout-modal" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header hyperion-t4">
				{{ languageService.getTranslation('MODAL.SESSION.TIMEOUT.TITLE') | async }}
			</div>
			<div class="modal-body">
				<p>
					{{ languageService.getTranslation('MODAL.SESSION.TIMEOUT.TEXT') | async }}
				</p>
			</div>
			<div class="modal-footer">
				<wdpr-button size="medium" (click)="authService.reauthenticate()">
					{{ languageService.getTranslation('MODAL.SESSION.TIMEOUT.REAUTH') | async }}
				</wdpr-button>
			</div>
		</div>
	</div>
</div>
