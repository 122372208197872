import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-body',
  templateUrl: './accordion-body.component.html',
  styleUrls: ['./accordion-body.component.scss']
})
export class AccordionBodyComponent implements OnInit {

  @Input() id:string | number = '';
  @Input() accordionParent:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
