import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss']
})
export class HelpSectionComponent implements OnInit {
	@Input() title = '';
	@Input() content = '';

  constructor() { }

  ngOnInit(): void {
  }

}
