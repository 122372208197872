import { Component } from '@angular/core';
import { PermissionsService } from './services/permissions.service';
import { LanguageService } from './services/language.service';
import { environment } from 'src/environments/environment';
import { ErrorService } from './services/error.service';
import { Error } from 'src/app/interfaces/error';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cast-profile';
	public error:Error | null = null;

	constructor(public permissionsService:PermissionsService,
							private errorService:ErrorService,
							public languageService:LanguageService) {
		this.errorService.errorChanged
			.subscribe((error) => this.error = error);
	}

	get environment() {
		return environment;
	}
};
