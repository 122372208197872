// cast-profile\src\app\services\modal.service.ts

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

declare const bootstrap:any;
type Modal = {
	id:string,
	modal:any,
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
	private modalResponse = new Subject<boolean>();

	private readonly debug = !environment.production;
  private modals:Array<Modal> = [];
	public openModals:Array<Modal> = [];

  constructor() { }

  public initModal(modalId:string) {
    if(this.modals.some(m => m.id === modalId)) return;

	const modal = document.getElementById(modalId);
    if(modal) {
			const newModal = {
				id: modalId,
				modal: new bootstrap.Modal(modal),
			};

			this.modals = [
				...this.modals,
				newModal,
			];
			this.addEventListener(modalId, 'shown.bs.modal', () => {
				this.openModals = [
					newModal,
					...this.openModals,
				];
			});
			this.addEventListener(modalId, 'hidden.bs.modal', () => {
				this.openModals = this.openModals.filter(om => om.id !== modalId);
			});
		}
    else if(this.debug) console.log('modal not found!', modalId);
  }

	public async closeAllModals() {
		if(this.openModals.length > 0) {
			const modalsClosed = await Promise.all(
				this.openModals.map(m => new Promise<boolean>((resolve, reject) => {
					this.addEventListener(
						m.id,
						'hidden.bs.modal',
						() => resolve(true)
					);
					this.closeModal(m.id);
				}))
			);
			return new Promise<boolean>((resolve, reject) => {
				if(modalsClosed.some(m => !m)) resolve(false);
				else resolve(true);
			});
		}
		return new Promise<boolean>((resolve, reject) => resolve(true));
	}

  public showModal(modalId:string) {
    const match = this.findModal(modalId);
    if(match) {
			match.modal.show();
		}
  }

  public closeModal(modalId:string) {
    const match = this.findModal(modalId);
    if(match) match.modal.hide();

  }

  public addEventListener(modalId:string, event:string, func:any) {
    const modal = document.getElementById(modalId);
    if(modal) modal.addEventListener(event, () => func());
  }

  public disposeModal(modalId:string) {
    const match = this.findModal(modalId);
    if(match) match.modal.dispose();
    this.modals = this.modals.filter(m => m.id !== modalId);
  }

  private findModal(modalId:string) {
    return this.modals.find(m => m.id === modalId);
  }

  getUserResponse(): Observable<boolean> {
    return this.modalResponse.asObservable();
  }

  setUserResponse(response: boolean) {
    this.modalResponse.next(response);
  }
}
