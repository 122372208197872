import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from 'src/app/services/language.service';
import { ModalService } from 'src/app/services/modal.service';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import '@wdpr/button';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly helpModalId = 'help-modal';
	public activeRoute = 'profile';
	public subRoute = '';

  constructor(private modalService:ModalService,
							public languageService:LanguageService,
							private router:Router,
							public sanitizer:DomSanitizer) {
		this.router.events
			.subscribe((event:NavigationEvent) => {
				if(event instanceof NavigationEnd) {
					const split = event.urlAfterRedirects.split('/');
					this.activeRoute = split[1];
					if(split.length === 3) {
						if(split[1] === 'profile') this.subRoute = 'view';
						else this.subRoute = split[2].split('?')[0];
					} else this.subRoute = '';
				}
			});
	}

  ngOnInit(): void {
  }

	ngAfterViewInit(): void {
		this.modalService.initModal(this.helpModalId);
	}

	ngOnDestroy(): void {
		this.modalService.disposeModal(this.helpModalId);
	}

}
