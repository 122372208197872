import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Email } from '../interfaces/email';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private proxy:string = environment.baseURL;
  private path:string = '/email';

  constructor(private http:HttpClient) { }

  // post email to send
  sendEmail(email:Email) {
    return this.http.post(
			`${this.proxy}${this.path}`,
					email
		);
  }

  sendErrorEmail(email:Email) {
    return this.http.post(
		`${this.proxy}${this.path}/error`,
        email
    );
  }
}
