<div class="modal fade" id="session-timeout-warning-modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header hyperion-t4">
				{{ languageService.getTranslation('MODAL.SESSION.WARNING.TITLE') | async }}
			</div>
			<div class="modal-body">
				<p>
					{{ languageService.getTranslation('MODAL.SESSION.WARNING.TEXT', { minutesUntilTimeout: authService.minutesUntilTimeout | number:'1.0-0', secondsUntilTimeout: authService.secondsUntilTimeout | number:'2.0-0' }) | async }}
				</p>
			</div>
			<div class="modal-footer">
				<wdpr-button size="medium" theme="secondary" data-bs-dismiss="modal" (click)="authService.keepWorking()">
					{{ languageService.getTranslation('MODAL.SESSION.WARNING.CONTINUE') | async }}
				</wdpr-button>
				<wdpr-button size="medium" (click)="authService.redirectToMyID()">
					{{ languageService.getTranslation('MODAL.SESSION.WARNING.REAUTH') | async }}
				</wdpr-button>
			</div>
		</div>
	</div>
</div>
