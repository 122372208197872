import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationModalService {
	public isOpen = false;

	public notificationModalOpenChanged = new Subject<boolean>();

  constructor(private modalService:ModalService) { }

	public setIsOpen(isOpen:boolean) {
		this.isOpen = isOpen;
		this.notificationModalOpenChanged.next(isOpen);
	}

	public showModal() {
		this.modalService.showModal('notification-modal');
	}

	public closeModal() {
		this.modalService.closeModal('notification-modal');
	}
}
