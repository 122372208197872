import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteActivator } from './services/route-activator.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [RouteActivator],
  },
  {
    path: 'profile-templates',
    loadChildren: () => import('./form-builder/form-builder.module').then(m => m.FormBuilderModule),
    canActivate: [RouteActivator],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'validate',
    loadChildren: () => import('./login-validate/login-validate.module').then(m => m.LoginValidateModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
    canActivate: [RouteActivator],
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./scheduler/scheduler.module').then(m => m.SchedulerModule),
    canActivate: [RouteActivator],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-view/admin-view.module').then(m => m.AdminViewModule),
    canActivate: [RouteActivator],
  },
  {
    path: 'insights',
    loadChildren: () => import('./profile-insights/profile-insights.module').then(m => m.ProfileInsightsModule),
    canActivate: [RouteActivator],
  },
	{
		path: '**',
		redirectTo: 'profile',
		pathMatch: 'full',
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
