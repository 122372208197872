import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { HelpToastComponent } from './components/help-toast/help-toast.component';
import { ManageProfilesHelpComponent } from './components/manage-profiles-help/manage-profiles-help.component';
import { ProfileHelpComponent } from './components/profile-help/profile-help.component';
import { ProfileBuilderHelpComponent } from './components/profile-builder-help/profile-builder-help.component';
import { SchedulerHelpComponent } from './components/scheduler-help/scheduler-help.component';
import { ProfileBuilderManagementHelpComponent } from './components/profile-builder-management-help/profile-builder-management-help.component';
import { HelpSectionComponent } from './components/help-section/help-section.component';



@NgModule({
  declarations: [
		HelpModalComponent,
		HelpToastComponent,
  ManageProfilesHelpComponent,
  ProfileHelpComponent,
  ProfileBuilderHelpComponent,
  SchedulerHelpComponent,
  ProfileBuilderManagementHelpComponent,
  HelpSectionComponent,
	],
  imports: [
    CommonModule
  ],
	exports: [
		HelpModalComponent,
		HelpToastComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HelpModule { }
