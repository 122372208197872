import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionTimeoutModalComponent } from './components/session-timeout-modal/session-timeout-modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SessionTimeoutWarningModalComponent } from './components/session-timeout-warning-modal/session-timeout-warning-modal.component';
import { LsLoadingSpinnerModule, } from '@etech-flex-sre/ls-loading-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { HelpEmailModalComponent } from './components/help-email-modal/help-email-modal.component';
import { FormsModule } from '@angular/forms';
import { FeedbackEmailModalComponent } from './components/feedback-email-modal/feedback-email-modal.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationCarouselComponent } from './components/notification-carousel/notification-carousel.component';
import { PermissionsModalComponent } from './components/permissions-modal/permissions-modal.component';
import { LsSortableTableModule } from '@etech-flex-sre/ls-sortable-table';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HelpModule } from './help/help.module';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SessionTimeoutModalComponent,
    NavbarComponent,
    SessionTimeoutWarningModalComponent,
    HelpEmailModalComponent,
    FeedbackEmailModalComponent,
    NotificationModalComponent,
    NotificationComponent,
    NotificationCarouselComponent,
    PermissionsModalComponent,
  ],
  imports: [
    BrowserModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
    HttpClientModule,
    LsLoadingSpinnerModule,
		NgSelectModule,
		LsSortableTableModule,
		HelpModule,
		SharedModule,
		FormsModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
