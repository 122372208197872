import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Email } from 'src/app/interfaces/email';
import { AuthService } from 'src/app/services/auth.service';
import { EmailService } from 'src/app/services/email.service';
import { LanguageService } from 'src/app/services/language.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-feedback-email-modal',
  templateUrl: './feedback-email-modal.component.html',
  styleUrls: ['./feedback-email-modal.component.scss'],
})
export class FeedbackEmailModalComponent implements AfterViewInit {
  @Output() mailSent:EventEmitter<boolean> = new EventEmitter();
  private readonly destroy$ = new Subject();
  public sending = false;
  public success = false;
  public issue = '';
  public castMemberName = '';
  public castMemberPerner = '';
  public castMemberLocation = '';
  public castMemberEmail = '';
  public castMemberContact = '';
  public contactPhone = true;
  public contactEmail = true;
  public errorText = '';
  private contact:Array<string> = [];


  constructor(private modalService:ModalService,
              public authService: AuthService,
							public languageService:LanguageService,
              private emailService:EmailService) {
    this.authService.authUserChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.setupModal(value);
      });
  }

  ngAfterViewInit(): void {
    this.modalService.initModal('feedback-email-modal');
		this.modalService.addEventListener(
			'feedback-email-modal',
			'hidden.bs.modal',
			() => this.reset()
		);
  }

  setupModal(authUser:any) {
    this.castMemberName = `${authUser.FirstName} ${authUser?.LastName}`;
    this.castMemberPerner = authUser.Perner;
    this.castMemberLocation = `${authUser.OrgUnitName} (${authUser.OrgUnitCode})`;
    this.castMemberEmail = authUser.email;
  }

  cannotSendEmail():boolean {
    return  !this.issue                                                ||
            !this.castMemberName                                       ||
            this.castMemberPerner.length !== 8                         ||
            !this.castMemberLocation                                   ||
            this.sending                                               ||
            (!this.castMemberEmail && !this.castMemberContact)         ||
            (!this.contactPhone && !this.contactEmail);
  }

  sendEmail() {
    this.sending = true;
		this.success = false;
		this.errorText = '';

    if(this.contactPhone) this.contact.push('Phone');
    if(this.contactEmail) this.contact.push('Email');

    const email:Email = {
			CastMemberName: this.castMemberName,
			CastMemberPerner: this.castMemberPerner,
			CastMemberEmail: this.castMemberEmail,
			CastMemberContact: this.castMemberContact,
			CastMemberLocation: this.castMemberLocation,
			Comment: this.issue,
			Category: 'feedback',
			ContactPhone: this.contactPhone,
			ContactEmail: this.contactEmail,
			Contact: this.contact,
    };

    this.emailService.sendEmail(email)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          const { message } = error.error;
          this.errorText = `${message}`;
          return of(null);
        })
      )
      .subscribe((value) => {
				if(value) {
					this.success = true;
					setTimeout(() => this.modalService.closeModal('feedback-email-modal'), 2500);
				}
        this.sending = false;
      });
  }

  reset() {
    this.contact = [];
		this.success = false;
		this.errorText = '';
		this.sending = false;
    this.contactEmail = true;
    this.contactPhone = true;
  }

}
