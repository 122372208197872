import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutWarningModalService {
	public isOpen = false;

	public sessionTimeoutWarningModalOpenChanged = new Subject<boolean>();

  constructor(private modalService:ModalService) { }

	public setIsOpen(isOpen:boolean) {
		this.isOpen = isOpen;
		this.sessionTimeoutWarningModalOpenChanged.next(isOpen);
	}

	public showModal() {
    this.modalService.showModal('session-timeout-warning-modal');
		this.setIsOpen(true);
  }

	public closeModal() {
    this.modalService.closeModal('session-timeout-warning-modal');
  }
}
