<div class="modal fade" id="feedback-email-modal">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title hyperion-t4">
					{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.TITLE') | async }}
				</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="hyperion-t6 mb-3">
					{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.HEADER') | async }}
				</div>
				<p class="text-danger" *ngIf="errorText">{{ errorText }}</p>
				<h4 class="text-success" *ngIf="success">
					{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.SUCCESS') | async }}
				</h4>
        <form>
					<div class="mb-3 form-row">
						<div class="col-lg-6 col-sm-12">
							<label for="contact-checkboxes">
								{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.CONTACT_PREFERENCE') | async }}
							</label>
							<br>
							<div class="form-check form-check-inline">
								<input class="form-check-input" name="contact-checkboxes" type="checkbox" id="Phone">
								<label class="form-check-label" for="Phone">
									{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.PHONE') | async }}
								</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" name="contact-checkboxes" type="checkbox" id="Email">
								<label class="form-check-label" for="Email">
									{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.EMAIL') | async }}
								</label>
							</div>
						</div>
					</div>
					<div class="mb-3">
						<label for="castMemberName">
							{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.YOUR_NAME') | async }}
						</label>
						<input class="form-control" name="castMemberName" id="castMemberName" [(ngModel)]="castMemberName"  minlength="4" maxlength="25" type="string" [disabled]="true"/>
					</div>
					<div class="mb-3">
						<label for="castMemberEmail">
							{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.DISNEY_EMAIL') | async }}
						</label>
						<input class="form-control" name="castMemberEmail" id="castMemberEmail" [(ngModel)]="castMemberEmail" minlength="4" maxlength="25" [disabled]="true" />
					</div>
					<div class="mb-3">
						<label for="castMemberContact">
							{{ authService.authUser?.email ? (languageService.getTranslation('MODAL.FEEDBACK_EMAIL.ADDITIONAL') | async) : '' }} {{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.ADDITIONAL_CONTACT') | async }}
						</label>
						<input class="form-control" name="castMemberContact" id="castMemberContact" [(ngModel)]="castMemberContact" minlength="10" maxlength="12" />
					</div>
					<div class="mb-3" *ngIf="!authService.authUser">
						<label for="castMemberPerner">
							{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.YOUR_PERNER') | async }}
						</label>
						<input class="form-control" name="castMemberPerner" id="castMemberPerner" [(ngModel)]="castMemberPerner" minlength="8" maxlength="8" />
					</div>
					<div class="mb-3" *ngIf="!authService.authUser">
						<label for="castMemberLocation">
							{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.YOUR_LOCATION') | async }}
						</label>
						<input class="form-control" name="castMemberLocation" id="castMemberLocation" [(ngModel)]="castMemberLocation" />
					</div>
					<div class="mb-3">
						<label for="problemTextArea">
							{{ languageService.getTranslation('MODAL.FEEDBACK_EMAIL.FEEDBACK') | async }}
						</label>
						<textarea class="form-control" name="problemTextArea" id="problemTextArea" rows="3" [(ngModel)]="issue" [placeholder]="languageService.getTranslation('MODAL.FEEDBACK_EMAIL.FEEDBACK_PLACEHOLDER') | async"></textarea>
					</div>
        </form>
    	</div>
			<div class="modal-footer">
				<wdpr-button theme="secondary" data-bs-dismiss="modal">
					{{ languageService.getTranslation('BUTTON.CANCEL') | async }}
				</wdpr-button>
				<wdpr-button
					(click)="sendEmail()"
					[disabled]="cannotSendEmail()"
				>
					<div *ngIf="sending; else sendButtonText" class="spinner-border" role="status">
						<span class="sr-only"></span>
					</div>
					<ng-template #sendButtonText>
						{{ languageService.getTranslation('BUTTON.SUBMIT') | async }}
					</ng-template>
				</wdpr-button>
      </div>
		</div>
	</div>
</div>
