import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-scheduler-help',
  templateUrl: './scheduler-help.component.html',
  styleUrls: ['./scheduler-help.component.scss']
})
export class SchedulerHelpComponent implements OnInit {

  constructor(public languageService:LanguageService) { }

  ngOnInit(): void {
  }

}
