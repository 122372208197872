import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../interfaces/auth-user';
import { NotificationModalService } from './notification-modal.service';
import { SessionTimeoutModalService } from './session-timeout-modal.service';
import { SessionTimeoutWarningModalService } from './session-timeout-warning-modal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
	private readonly debug = !environment.production;
  private warningTimeOut = 5;
  public authUser:AuthUser | null = null;
  public minutesUntilTimeout = 0;
  public secondsUntilTimeout = 0;
  public authUserChange:Subject<AuthUser | null> = new Subject();
  keepWorkingTag: boolean = false;

  constructor(private http:HttpClient,
							private notificationModal:NotificationModalService,
              private sessionTimeoutModal:SessionTimeoutModalService,
							private sessionTimeoutWarningModal:SessionTimeoutWarningModalService) { }

  public setAuthUser(user:AuthUser | null = null) {
    if(this.debug) console.log('auth user', user);
    this.authUser = user;

    if(user) {
      this.setTimeout(user);
    }

    this.authUserChange.next(user);
  }

	public reauthenticate() {
		this.setToken();
		this.redirectToMyID();
	}

  public setToken(item:any = null) {
    if(item) localStorage.setItem(environment.tokenStorageName, JSON.stringify(item));
    else localStorage.removeItem(environment.tokenStorageName);
  }

  // eslint-disable-next-line camelcase
  public validateMyIDTokens(access_token:string, id_token:string):Observable<AuthUser> {
    return this.http.post<AuthUser>(
      `${environment.baseURL}/login`,
      // eslint-disable-next-line camelcase
      { access_token, id_token }
    );
  }

  public redirectToMyID() {
    /* eslint-disable max-len */
    const redirURL = `https://idp.${environment.myIDenv}.disney.com/as/authorization.oauth2?client_id=${environment.clientID}&response_type=id_token+token&redirect_uri=${environment.redirect}&nonce=APPLICATION_GENERATED_ONE_TIME_NONCE&scope=openid+profile+email+relationship.employeeNumber+relationship.employeeId`;
    document.location.href = redirURL;
  }

  private setTimeout(user: AuthUser) {
    this.minutesUntilTimeout = differenceInMinutes(new Date(user.exp), new Date());
    this.secondsUntilTimeout = differenceInSeconds(new Date(user.exp), new Date()) % 60;

    if (this.minutesUntilTimeout <= 0 && this.secondsUntilTimeout <= 0) {
        this.sessionTimeoutModal.showModal();
        return;
    }

    const updateTimeout = () => {
        this.minutesUntilTimeout = differenceInMinutes(new Date(user.exp), new Date());
        this.secondsUntilTimeout = differenceInSeconds(new Date(user.exp), new Date()) % 60;

        if (this.minutesUntilTimeout <= 0 && this.secondsUntilTimeout <= 0) {
            this.notificationModal.closeModal();
            this.sessionTimeoutWarningModal.closeModal();
            this.sessionTimeoutModal.showModal();
            return;
        } else if (this.minutesUntilTimeout < this.warningTimeOut && !this.keepWorkingTag) {
            this.notificationModal.closeModal();
            this.sessionTimeoutWarningModal.showModal();
        }

        requestAnimationFrame(updateTimeout);
    };

    requestAnimationFrame(updateTimeout);
}


  public keepWorking() {
    this.keepWorkingTag = true;
  }

  public signOut() {
    this.setToken();
    this.setAuthUser(null);
    document.location.href = 'https://login.myid.disney.com/logout';
  }
}
