import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-wrapper',
  templateUrl: './accordion-wrapper.component.html',
  styleUrls: ['./accordion-wrapper.component.scss']
})
export class AccordionWrapperComponent implements OnInit {
  @Input() accordionId:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
