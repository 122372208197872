import { Injectable } from '@angular/core';
import { Error } from 'src/app/interfaces/error';
import { Subject } from 'rxjs';
import { ErrorType } from '../interfaces/error-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
	public error:Error | null = null;

	public errorChanged = new Subject<Error | null>();

  constructor() { }

	public setError(error:Error | null) {
		this.error = error;
		this.errorChanged.next(error);
	}

	public handleError(errorType:ErrorType, additionalInfo:string) {
		this.setError({
			errorType,
			additionalInfo,
		});
	}
	public generateError(errorType:ErrorType, additionalInfo:string):Error {
		return {
			errorType,
			additionalInfo,
		};
	}
}
