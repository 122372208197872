<div class="modal fade" id="permissions-modal">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header hyperion-t4">
				Your Permissions
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>Below is a list of the permissions assigned to you per org unit. If you believe your permissions are incorrect, or you are missing any org units, please reach out for assistance.</p>
				<div class="row gy-3 mb-3">
					<div class="col-12 col-lg-6">
						<label for="filter-permissions-org-group">Filter org groups</label>
						<ng-select
							id="filter-permissions-org-group"
							[items]="userOrgGroups"
							bindLabel="OrgGroupName"
							[(ngModel)]="filterOrgGroup"
							(change)="updateFilterOrgGroup($event)"
						></ng-select>
					</div>
					<div class="col-12 col-lg-6">
						<label for="filter-permissions-org-units">Filter org units</label>
						<ng-select
							id="filter-permissions-org-units"
							[items]="filteredOrgUnits"
							bindLabel="OrgUnitName"
							[(ngModel)]="selectedOrgUnits"
							[multiple]="true"
							[closeOnSelect]="false"
							(change)="updateFilterOrgUnits($event)"
						></ng-select>
					</div>
				</div>
				<ls-sortable-table
					[columns]="columns"
					[data]="shownPermissions"
					noResultsLabel="No permissions for the selected org group and/or org unit."
				></ls-sortable-table>
    	</div>
			<div class="modal-footer">
				<wdpr-button data-bs-dismiss="modal">Close</wdpr-button>
      </div>
		</div>
	</div>
</div>
