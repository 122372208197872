import { Component, Input } from '@angular/core';
import { ErrorCode } from 'src/app/interfaces/error-code.enum';
import { ErrorType } from 'src/app/interfaces/error-type.enum';
import { LanguageService } from 'src/app/services/language.service';
import { Error } from 'src/app/interfaces/error';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {
	@Input() error:Error | null = null;
	public collapsed = true;

  constructor(public languageService:LanguageService) { }

	getTranslationKey(errorType:ErrorType) {
		return `ERRORS.${ErrorCode[errorType]}`;
	}

}
