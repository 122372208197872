import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, of, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrgUnit } from '../interfaces/org-unit';
import { OrgGroup } from '../interfaces/org-group';
import { Permissions } from '../interfaces/permissions';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
	private debug = !environment.production;
	public userPermissions:Array<Permissions> = [];
	public loadingUserPermissions = false;
	public permissionsChanged = new Subject<Array<Permissions>>();

  constructor(private http:HttpClient,
			 public authService:AuthService) { }

	public get isSuperAdmin(){
		return (this.authService.authUser?.RoleID === 3);
	}

	public get userOrgGroups():Array<OrgGroup> {
		const orgGroupIds = Array.from(
			new Set(this.userPermissions.map(up => up.OrgGroupID!))
		);
		return orgGroupIds.map((ogId) => {
			const orgGroup = this.userPermissions.find(up => up.OrgGroupID === ogId);
			return {
				OrgGroupID: ogId,
				OrgGroupName: orgGroup ? orgGroup.OrgGroupName! : '',
				OrgGroupEmail: orgGroup ? orgGroup.OrgGroupEmail! : '',
			};
		});
	}

	public get userOrgUnits():Array<OrgUnit> {
		return this.userPermissions.map((up) => ({
			OrgUnitID: up.OrgUnitID!,
			OrgGroupID: up.OrgGroupID!,
			OrgUnitName: up.OrgUnitName!,
			OrgGroupName: up.OrgGroupName!,
			OrgUnitCode: up.OrgUnitCode!,
			ActiveFormID: -1,
		}));
	}

	public get isAdmin() {
		return this.userPermissions.some(ou => ou.IsAdmin);
	}

	public get canOnBehalf() {
		return this.userPermissions.some(ou => ou.CanOnBehalf);
	}

	public get canManage() {
		return this.userPermissions.some(ou => ou.CanManage);
	}

	public get canBuild() {
		return this.userPermissions.some(ou => ou.CanBuild);
	}

	public get canSchedule() {
		return this.userPermissions.some(ou => ou.CanSchedule);
	}

	public get canView() {
		return this.userPermissions.some(ou => ou.CanView);
	}

	public getIsAdmin() {
		return this.userPermissions.filter(ou => ou.IsAdmin);
	}

	public getCanOnBehalf() {
		return this.userPermissions.filter(ou => ou.CanOnBehalf);
	}

	public getCanManage() {
		return this.userPermissions.filter(ou => ou.CanManage);
	}

	public getCanBuild() {
		return this.userPermissions.filter(ou => ou.CanBuild);
	}

	public getCanSchedule() {
		return this.userPermissions.filter(ou => ou.CanSchedule);
	}

	public getCanView() {
		return this.userPermissions.filter(ou => ou.CanView);
	}

	public getCanManageOrgUnit(og:OrgUnit | null) {
		if(og === null) return false;

		const match = this.userPermissions.find(up => up.OrgUnitID === og.OrgUnitID);
		if(match) return match.CanManage;
		return false;
	}

	public getIsOrgGroupAdmin(ogId:number) {
		return this.userPermissions.filter(ou => ou.OrgGroupID === ogId).some(ou => ou.IsAdmin);
	}

	public getIsOrgUnitAdminById(ogid:number | null) {
		const match = this.userPermissions.find(up => up.OrgUnitID === ogid);
		if(match) return match.IsAdmin;
		return false;
	}

	public getCanViewOrgUnitById(ogid:number | null) {
		if(ogid === null) return true;

		const match = this.userPermissions.find(up => up.OrgUnitID === ogid);
		if(match) return match.CanView;
		return false;
	}

	public getIsOrgUnitAdmin(og:OrgUnit | null) {
		if(og === null) return false;

		const match = this.userPermissions.find(up => up.OrgUnitID === og.OrgUnitID);
		if(match) return match.IsAdmin;
		return false;
	}

	public getCanBuildOrgUnit(og:OrgUnit | null) {
		if(og === null) return false;

		const match = this.userPermissions.find(up => up.OrgUnitID === og.OrgUnitID);
		if(match) return match.CanBuild;
		return false;
	}

	public getCanViewOrgUnit(og:OrgUnit | null) {
		if(og === null) return false;

		const match = this.userPermissions.find(up => up.OrgUnitID === og.OrgUnitID);
		if(match) return match.CanView;
		return false;
	}

	public getCanViewOrgGroup(orgGroupId:number) {
		return this.userPermissions.some(up => up.OrgGroupID === orgGroupId && up.CanView);
	}

	public getCanScheduleOrgGroup(orgGroupId:number) {
		return this.userPermissions.some(up => up.OrgGroupID === orgGroupId && up.CanSchedule);
	}

	public getCanManageOrgGroup(orgGroupId:number) {
		return this.userPermissions.some(up => up.OrgGroupID === orgGroupId && up.CanManage);
	}

	public getCanBuildOrgGroup(orgGroupId:number) {
		return this.userPermissions.some(up => up.OrgGroupID === orgGroupId && up.CanBuild);
	}

	public getCanManagebyId(ogid:number | undefined){
		const match = this.userPermissions.find(up => up.OrgUnitID === ogid);
		if(match) return (match.CanManage);
		return true;
	}
	public async loadUserPermissions(perner:string) {
		this.loadingUserPermissions = true;
		const permissions = await firstValueFrom(
			this.getUserPermissions(perner)
				.pipe(
					catchError((error) => {
						console.error(error);
						return of([]);
					})
				)
		);
		this.userPermissions = permissions;
		this.permissionsChanged.next(permissions);
		if(this.debug) console.log('user permissions', permissions);
		this.loadingUserPermissions = false;
	}

	public fillOrgUnitName(permissions:Array<Permissions>) {
		return permissions.map((p) => {
			return {
				...p,
				OrgUnitFullName: `${p.OrgUnitName} (${p.OrgUnitCode})`,
			};
		});
	}

	public getUserPermissions(perner:string) {
		return this.http.get<Array<Permissions>>(`${environment.baseURL}/cast-member/${perner}/permissions`);
	}

	public updatePermissions(perner:string, permissions:Array<Permissions>) {
		return this.http.put<{ message:string }>(`${environment.baseURL}/cast-member/${perner}/permissions`, { Permissions: permissions });
	}

	public createPermissions(perner:string, permissions:Array<Permissions>) {
		return this.http.post<{ message:string }>(`${environment.baseURL}/cast-member/${perner}/permissions`, { Permissions: permissions });
	}
}
